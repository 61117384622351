import { urls } from "./urls";
import axios from "axios";

const getToken = () => localStorage.getItem("token");

export const apiConfig = {
  // api base url
  // Upload
  uploadImage: async function (imageUrl) {
    try {
      const response = await axios.post(
        urls.uploadImageUrl,
        { image: imageUrl },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching all users:", error);
      throw error;
    }
  },

  // All Users
  getAllUsers: async function () {
    try {
      const response = await axios.get(urls.allUsers, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching all users:", error);
      throw error;
    }
  },

  getUserById: async function (userId) {
    try {
      const response = await axios.get(urls.getUserById, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching user by ID:", error);
      throw error;
    }
  },

  addUser: async function (userData) {
    try {
      const response = await axios.post(urls.addUser, userData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding user:", error);
      throw error;
    }
  },

  updateUser: async function (userId, updatedData) {
    try {
      const response = await axios.patch(urls.updateUser, updatedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
  },

  deactivateUser: async function (userId) {
    try {
      const response = await axios.patch(
        urls.deactivateUser.replace(":id", userId),
        null,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deactivating user:", error);
      throw error;
    }
  },

  activateUser: async function (userId) {
    try {
      const response = await axios.patch(
        urls.activateUser.replace(":id", userId),
        null,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error activating user:", error);
      throw error;
    }
  },

  deleteUser: async function (userId) {
    try {
      const response = await axios.delete(
        urls.deleteUser.replace(":id", userId),
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error;
    }
  },

  searchUsers: async function (query) {
    try {
      const response = await axios.get(urls.searchUsers, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        params: { query },
      });
      return response;
    } catch (error) {
      console.error("Error searching users:", error);
      throw error;
    }
  },

  // All Products
  addProduct: async function (productData) {
    try {
      const response = await axios.post(urls.addProduct, productData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  updateProduct: async function (productId, productData) {
    try {
      const response = await axios.patch(
        `${urls.updateProduct}/${productId}`,
        productData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  getProductById: async function (productId) {
    try {
      const response = await axios.get(`${urls.getProductbyId}/${productId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  deleteProduct: async function (productId) {
    try {
      const response = await axios.patch(
        `${urls.deleteProduct}/${productId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  allProducts: async function () {
    try {
      const response = await axios.get(`${urls.allProducts}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  // All Categories
  allCategories: async function () {
    try {
      const response = await axios.get(`${urls.allCategories}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  getCategoriebyId: async function (categoryId) {
    try {
      const response = await axios.get(
        `${urls.getCategoryById}/${categoryId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  addCategorie: async function (categorie) {
    try {
      const response = await axios.post(`${urls.addCategorie}`, categorie, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
        },
      });
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  updateCategorie: async function (categorie, categoryId) {
    try {
      const response = await axios.patch(
        `${urls.updateCategorie}/${categoryId}`,
        categorie,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`, // Pass the token in the headers
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error; // Throw error to be handled by the calling function
    }
  },

  // All Discount Prices
  createDiscount: async function (discountData) {
    try {
      const response = await axios.post(urls.createDiscount, discountData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error creating discount:", error);
      throw error;
    }
  },

  getDiscountById: async function (id) {
    try {
      const response = await axios.get(
        urls.getDiscountById.replace(":id", id),
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching discount by ID:", error);
      throw error;
    }
  },

  getAllDiscounts: async function () {
    try {
      const response = await axios.get(urls.getAllDiscounts, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching all discounts:", error);
      throw error;
    }
  },

  updateDiscount: async function (discountId, discountData) {
    try {
      const response = await axios.patch(
        `${urls.updateDiscount.replace(":id", discountId)}`,
        discountData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error updating discount:", error);
      throw error;
    }
  },

  deleteDiscount: async function (discountId) {
    try {
      const response = await axios.delete(
        `${urls.deleteDiscount.replace(":id", discountId)}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error deleting discount:", error);
      throw error;
    }
  },

  applyDiscount: async function (discountCode) {
    try {
      const response = await axios.post(
        urls.applyDiscount,
        { code: discountCode },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error applying discount:", error);
      throw error;
    }
  },

  validateDiscount: async function (discountCode) {
    try {
      const response = await axios.post(
        urls.validateDiscount,
        { code: discountCode },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error validating discount:", error);
      throw error;
    }
  },

  // All Orders
  createOrder: async function (orderData) {
    try {
      const response = await axios.post(urls.createOrder, orderData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error creating order:", error);
      throw error;
    }
  },

  getAllOrders: async function () {
    try {
      const response = await axios.get(urls.getAllOrders, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching all orders:", error);
      throw error;
    }
  },
  getAllCancelOrder: async function () {
    try {
      const response = await axios.get(urls.getAllCancelOrder, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching all orders:", error);
      throw error;
    }
  },

  getOrderById: async function (orderId) {
    try {
      const response = await axios.get(
        urls.getOrderById.replace(":id", orderId),
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error(`Error fetching order with ID ${orderId}:`, error);
      throw error;
    }
  },

  updateOrderById: async function (orderId, updateData) {
    try {
      const response = await axios.patch(
        urls.updateOrderById.replace(":id", orderId),
        updateData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error(`Error updating order with ID ${orderId}:`, error);
      throw error;
    }
  },

  updateTicketById: async function (ticketId, updateData) {
    try {
      const response = await axios.patch(
        urls.updateTicketById.replace(":id", ticketId),
        updateData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error(`Error updating order with ID ${ticketId}:`, error);
      throw error;
    }
  },

  deleteOrder: async function (orderId) {
    try {
      const response = await axios.delete(
        urls.deleteOrder.replace(":id", orderId),
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error(`Error deleting order with ID ${orderId}:`, error);
      throw error;
    }
  },

  // All Carasoul
  createCarousel: async function (carouselData) {
    try {
      const response = await axios.post(urls.createCarousel, carouselData, {
        headers: {
          "Content-Type": "multipart/form-data", // required for image uploads
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error creating carousel item:", error);
      throw error;
    }
  },

  // Get all carousel items
  getAllCarousels: async function () {
    try {
      const response = await axios.get(urls.getAllCarousels, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching all carousel items:", error);
      throw error;
    }
  },

  // Get carousel item by ID
  getCarouselById: async function (carouselId) {
    try {
      const response = await axios.get(
        urls.getCarouselById.replace(":id", carouselId),
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error(
        `Error fetching carousel item with ID ${carouselId}:`,
        error
      );
      throw error;
    }
  },

  // Delete carousel item by ID
  deleteCarousel: async function (carouselId) {
    try {
      const response = await axios.delete(
        urls.deleteCarousel.replace(":id", carouselId),
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error(
        `Error deleting carousel item with ID ${carouselId}:`,
        error
      );
      throw error;
    }
  },

  getAllQuery: async function () {
    try {
      const response = await axios.get(urls.allQuery, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response;
    } catch (error) {
      console.error(`Error fetching carousel item with ID :`, error);
      throw error;
    }
  },
};
