import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { apiConfig } from "../../utils/apiConfig";
import {
  TextField,
  MenuItem,
  Button,
  Select,
  InputLabel,
  FormControl,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";

export const AddProduct = () => {
  const location = useLocation();
  const { productId } = location.state || {};
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productName, setProductName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategorySlug, setSelectedCategorySlug] = useState("");
  const [price, setPrice] = useState();
  const [sellingPrice, setSellingPrice] = useState();
  const [quantity, setQuantity] = useState();
  const [description, setDescription] = useState("");
  const [edit, setEdit] = useState(false);
  const [showImage, setShowImage] = useState([]);
  const [showSecondaryImage, setShowSecondaryImage] = useState([]);
  const [image, setImage] = useState();
  const [sizes, setSizes] = useState([]);
  const [secondImage, setSecondImage] = useState([]);
  // const [secondImageUrl, setSecondImageUrl] = useState([]);
  const navigate = useNavigate();
  const availableSizes = ["XS", "S", "M", "L", "XL", "XXL"];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiConfig.allCategories();
        const data = response?.data?.data?.filter(
          (e) => e.categoryActive === true
        );
        console.log(data, "data");
        setCategories(data); // Assuming the API returns user data in response.data
        setSizes(data.sizes || []);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          // If the token is expired or invalid
          localStorage.removeItem("token"); // Remove the expired token
          navigate("/"); // Redirect to login page
        }
      } finally {
        setLoading(false);
      }
    };

    const fetchProductData = async () => {
      if (productId) {
        try {
          const response = await apiConfig.getProductById(productId);

          const data = response.data.data;
          setProductName(data.productName);
          setSelectedCategory(data.categoryId._id);
          setSelectedCategorySlug(data.categoryId.slug);
          setPrice(data.price);
          setSellingPrice(data.sellingPrice);
          setQuantity(data.stockQuantity);
          setDescription(data.description);
          setSizes(data.sizes);
          setShowImage((prevData) => [data.primaryImageUrl]);
          setShowSecondaryImage(data.secondaryImageUrl);
          setEdit(true);
        } finally {
          setLoading(false); // Set loading to false after fetching
        }
      } else {
        setLoading(false); // If no productId, just set loading to false
      }
    };
    fetchProductData();
    fetchUserData();
  }, [productId]);

  const handleSelectCategory = (e) => {
    const value = e.target.value;
    setSelectedCategory(value);
    const category = categories.find((e) => e._id === value);
    console.log(category?.slug, "CategoryCaluie");
    setSelectedCategorySlug(category.slug);
  };
  const handleImage = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSizeChange = (size) => {
    // Toggle size selection
    setSizes((prevSizes) => {
      if (prevSizes.includes(size)) {
        return prevSizes.filter((s) => s !== size); // Remove size if already selected
      } else {
        return [...prevSizes, size]; // Add size if not selected
      }
    });
  };

  const handleSecondImage = (event) => {
    console.log(event.target.files, "Event Check");
    setSecondImage(event.target.files);
  };

  const uploadSecondImage = async (images) => {
    // Convert images to an array if it’s not already
    const imagesArray = Array.isArray(images) ? images : Array.from(images);

    console.log(imagesArray, "check images array");

    try {
      // Wait for all image uploads to complete before proceeding
      const imgUrls = await Promise.all(
        imagesArray.map(async (image) => {
          const imgUrl = await apiConfig.uploadImage(image);
          return imgUrl?.data?.imageUrl;
        })
      );
      // const imgUrls = ["122", "123", "124"];
      console.log(imgUrls, "ImgUrls");

      // Store the array of URLs in state once all images are uploaded
      // setSecondImageUrl(imgUrls);
      console.log(imgUrls, "All images uploaded successfully");
      return imgUrls; // Return the URLs if needed by the calling function
    } catch (error) {
      console.error("Error uploading images:", error);
      throw error; // Throw the error to handle it outside this function if needed
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setLoading(true);

    return;
    let secondImageUrl;
    if (secondImage) {
      secondImageUrl = await uploadSecondImage(secondImage);
    }
    const formData = new FormData(); // Create a new FormData object

    formData.append("productName", productName);
    formData.append("categoryId", selectedCategory);
    formData.append("categorySlug", selectedCategorySlug);
    formData.append("price", Number(price));
    sizes.forEach((size) => formData.append("sizes[]", size));
    formData.append("sellingPrice", Number(sellingPrice));
    formData.append("stockQuantity", Number(quantity));
    formData.append("description", description);
    // formData.append("secondaryImageUrl", secondImageUrl);
    console.log(secondImageUrl, "SecondImageUrl");
    secondImageUrl.forEach((tag) => {
      formData.append("secondaryImageUrl[]", tag); // Append each item with the same key (common for array handling)
    });

    if (!edit) {
      try {
        const imgUrl = await apiConfig.uploadImage(image);
        console.log(imgUrl, "Img");
        formData.append("primaryImageUrl", imgUrl?.data?.imageUrl);
        // formData.append("primaryImageUrl", "234");
        const response = await apiConfig.addProduct(formData);
        setLoading(false);
        // Handle success (e.g., show a success message or redirect)
        console.log("Product added successfully:", response.data);
        navigate("/products"); // Redirect to the products page
      } catch (err) {
        console.error("Error adding product:", err);
        setLoading(false);
        // Handle error (e.g., show an error message)
      }
    } else {
      try {
        const imgUrl = await apiConfig.uploadImage(image);
        console.log(imgUrl, "Img");
        formData.append("primaryImageUrl", imgUrl?.data?.imageUrl);
        const response = await apiConfig.updateProduct(productId, formData);
        // Handle success (e.g., show a success message or redirect)
        console.log("Product added successfully:", response.data);
        setLoading(false);
        navigate("/products"); // Redirect to the products page
      } catch (err) {
        console.error("Error adding product:", err);
        setLoading(false);
        // Handle error (e.g., show an error message)
      }
    }
  };

  const handleSellingPriceChange = (e) => {
    const value = parseFloat(e.target.value); // Parse the input value
    setSellingPrice(e.target.value); // Update the selling price

    if (!isNaN(value)) {
      // Check if the parsed value is a number
      const calculatedPrice = value + value * 0.8; // Calculate 40% increase
      setPrice(calculatedPrice); // Update the price
    } else {
      setPrice(0); // Reset price if input is not a valid number
    }
  };

  return (
    <>
      <div class="row">
        <div class="page-header">
          <h3 class="page-title"> Product Tables </h3>
          <nav>
            <Link to={"/products"} class="btn btn-gradient-dark btn-fw">
              Back
            </Link>
          </nav>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{!edit ? "Add" : "Edit"} Product</h4>
              <p class="card-description"> Basic form elements </p>
              <Box display="flex" flexDirection="column" gap={2}>
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      label="Product Name"
                      variant="outlined"
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                      required
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth required>
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={selectedCategory}
                        onChange={handleSelectCategory}
                        label="Category"
                      >
                        <MenuItem value="">
                          <em>Select a Category</em>
                        </MenuItem>
                        {categories?.map((category) => (
                          <MenuItem key={category._id} value={category._id}>
                            {category.categoryName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: 2 }} display="flex" gap={2}>
                    <div>
                      <p>Primary Image</p>
                      <input type="file" onChange={handleImage} />
                      {showImage?.map((item) => (
                        <div
                          style={{
                            width: "25%",
                            height: "25%",
                            marginTop: "10px",
                          }}
                        >
                          <img
                            src={item}
                            alt="Google Drive"
                            width={100}
                            height={100}
                          ></img>
                        </div>
                      ))}
                    </div>
                    <div>
                      <p>Secondary Image</p>
                      <input
                        type="file"
                        onChange={handleSecondImage}
                        multiple
                      />
                      <div
                        style={{
                          display: "flex",
                          gap: " 2rem",
                          marginTop: "10px",
                        }}
                      >
                        {showSecondaryImage?.map((item) => (
                          <div style={{ width: "25%", height: "25%" }}>
                            <img
                              src={item}
                              alt="Google Drive"
                              width={100}
                              height={100}
                            ></img>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Box>
                  <p style={{ color: "red" }}>
                    Image Size should not be greater then 8 MB
                  </p>
                  <Box sx={{ mb: 2 }} display="flex" gap={2}>
                    <TextField
                      label="Selling Price"
                      type="number"
                      variant="outlined"
                      value={sellingPrice}
                      onChange={handleSellingPriceChange}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                    <TextField
                      label="Price"
                      type="number"
                      variant="outlined"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      label="Quantity"
                      type="number"
                      variant="outlined"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Sizes
                    </Typography>
                    <FormGroup row>
                      {availableSizes.map((size) => (
                        <FormControlLabel
                          key={size}
                          control={
                            <Checkbox
                              checked={sizes.includes(size)}
                              onChange={() => handleSizeChange(size)}
                              name={size}
                            />
                          }
                          label={size}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      label="Description"
                      variant="outlined"
                      multiline
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      startIcon={
                        loading && (
                          <CircularProgress size={20} color="inherit" />
                        )
                      }
                    >
                      {loading ? "Processing" : edit ? "Update" : "Submit"}
                    </Button>
                    {/* <Button type="submit" variant="contained" color="primary">
                      {edit ? "Update" : "Submit"}
                    </Button> */}
                    <Button variant="outlined" color="secondary" sx={{ ml: 2 }}>
                      Cancel
                    </Button>
                  </Box>
                </form>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
