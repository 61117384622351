import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { apiConfig } from "../../utils/apiConfig";

export const AddDiscount = () => {
  const location = useLocation();
  const { discountId } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    code: "",
    description: "",
    discountType: "", // default to 'percentage' or 'fixed'
    amount: "",
    minPurchaseAmount: "",
    maxDiscountAmount: "",
    startDate: "",
    expiryDate: "",
    usageLimit: "",
    usageCount: "",
    active: true,
  });
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();

  const formatDate = (isoDate) => {
    return new Date(isoDate).toISOString().split("T")[0];
  };

  useEffect(() => {
    const fetchProductData = async () => {
      if (discountId) {
        try {
          const response = await apiConfig.getDiscountById(discountId);
          const data = response.data.data;
          const formattedData = {
            ...data,
            startDate: formatDate(data.startDate),
            expiryDate: formatDate(data.expiryDate),
          };
          setFormData(formattedData);
          setEdit(true);
        } finally {
          setLoading(false); // Set loading to false after fetching
        }
      } else {
        setLoading(false); // If no productId, just set loading to false
      }
    };
    fetchProductData();
  }, [discountId]);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    console.log(formData, "Form Data");
    if (!edit) {
      try {
        const response = await apiConfig.createDiscount(formData);
        // Handle success (e.g., show a success message or redirect)
        console.log("Discount added successfully:", response.data);
        navigate("/discount"); // Redirect to the Discounts page
      } catch (err) {
        console.error("Error adding Discount:", err);
        // Handle error (e.g., show an error message)
      }
    } else {
      try {
        const response = await apiConfig.updateDiscount(discountId, formData);
        // Handle success (e.g., show a success message or redirect)
        console.log("Discount added successfully:", response.data);
        navigate("/discount"); // Redirect to the Discounts page
      } catch (err) {
        console.error("Error adding Discount:", err);
        // Handle error (e.g., show an error message)
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div class="row">
        <div class="page-header">
          <h3 class="page-title"> Product Tables </h3>
          <nav>
            <Link to={"/discount"} class="btn btn-gradient-dark btn-fw">
              Back
            </Link>
          </nav>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{!edit ? "Add" : "Edit"} Add Discount</h4>
              <p class="card-description"> Basic form elements </p>
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap={2}>
                  {/* First Row */}
                  <Box display="flex" gap={2} flexWrap="nowrap" width="100%">
                    <TextField
                      sx={{ width: "50%" }} // 50% width for two fields in one row
                      label="Code"
                      name="code"
                      value={formData.code}
                      onChange={handleChange}
                      required
                    />

                    <TextField
                      sx={{ width: "50%" }} // 50% width for two fields in one row
                      label="Description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                    />
                  </Box>
                  <Box display="flex" gap={2} flexWrap="nowrap" width="100%">
                    <TextField
                      sx={{ width: "50%" }} // 50% width for two fields in one row
                      label="Amount"
                      name="amount"
                      type="number"
                      value={formData.amount}
                      onChange={handleChange}
                      required
                    />
                  </Box>
                  {/* Second Row */}
                  <Box display="flex" gap={2} flexWrap="nowrap" width="100%">
                    <TextField
                      select
                      sx={{ width: "50%" }} // 50% width for two fields in one row
                      label="Discount Type"
                      name="discountType"
                      value={formData.discountType}
                      onChange={handleChange}
                    >
                      <MenuItem value="percentage">Percentage</MenuItem>
                      <MenuItem value="fixed">Fixed</MenuItem>
                    </TextField>

                    <TextField
                      sx={{ width: "50%" }} // 50% width for two fields in one row
                      label="Minimum Purchase"
                      name="minPurchaseAmount"
                      type="number"
                      value={formData.minPurchaseAmount}
                      onChange={handleChange}
                      required
                    />
                  </Box>

                  {/* Third Row */}
                  <Box display="flex" gap={2} flexWrap="nowrap" width="100%">
                    <TextField
                      sx={{ width: "50%" }} // 50% width for two fields in one row
                      label="Maximum Discount Amount"
                      name="maxDiscountAmount"
                      type="number"
                      value={formData.maxDiscountAmount}
                      onChange={handleChange}
                      required
                    />

                    <TextField
                      sx={{ width: "50%" }} // 50% width for two fields in one row
                      label="Start Date"
                      name="startDate"
                      type="date"
                      value={formData.startDate}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </Box>

                  {/* Fourth Row */}
                  <Box display="flex" gap={2} flexWrap="nowrap" width="100%">
                    <TextField
                      sx={{ width: "50%" }} // 50% width for two fields in one row
                      label="Expiry Date"
                      name="expiryDate"
                      type="date"
                      value={formData.expiryDate}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />

                    <TextField
                      sx={{ width: "50%" }} // 50% width for two fields in one row
                      label="Usage Limit"
                      name="usageLimit"
                      type="number"
                      value={formData.usageLimit}
                      onChange={handleChange}
                      required
                    />
                  </Box>

                  {/* Fifth Row */}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.active}
                        onChange={handleChange}
                        name="active"
                        color="primary"
                      />
                    }
                    label="Active"
                  />

                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Box>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
