import { OrderTable } from "../subcomponents/OrderTable"
import { Footer } from "./Footer"

export const OrderView = () => {
    return (
        <>
            <div class="main-panel">
               <div class="content-wrapper">
                   <OrderTable/>
               </div>

               
           
           
               <Footer/>
           
               </div>
        </>
    )
} 