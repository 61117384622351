import { CarasoulView } from "../../components/MainFrontEnd/CarasoulView"
import { SideNavigation } from "../../components/SideNavigation"
import { TopNavigation } from "../../components/TopNavigation"

export const Carasoul = () => {
    return (
        <>
            <div class="container-scroller">

            <TopNavigation/>

            <div class="container-fluid page-body-wrapper">

                <SideNavigation/>
                
                <CarasoulView/>

            </div>

            </div>
            <script src="assets/vendors/js/vendor.bundle.base.js"></script>
            <script src="assets/vendors/chart.js/chart.umd.js"></script>
            <script src="assets/vendors/bootstrap-datepicker/bootstrap-datepicker.min.js"></script>
            <script src="assets/js/off-canvas.js"></script>
            <script src="assets/js/misc.js"></script>
            <script src="assets/js/settings.js"></script>
            <script src="assets/js/todolist.js"></script>
            <script src="assets/js/jquery.cookie.js"></script>
            <script src="assets/js/dashboard.js"></script>
        </>
    )
}