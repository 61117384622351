import { useLocation } from "react-router-dom"
import { ProductTable } from "../subcomponents/ProductTable"
import { Footer } from "./Footer"
import { AddProduct } from "../subcomponents/Add/AddProduct";

export const ProductView = () => {
    const location = useLocation();
    return (
        <>
             <div class="main-panel">
               <div class="content-wrapper">
                {location.pathname === '/products' && <ProductTable/>}
                {location.pathname === '/addProduct' && <AddProduct/>}
                {/* <AddProduct/> */}
               </div>
               <Footer/>
           
               </div>
        </>
    )
} 