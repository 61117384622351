import { Link } from "react-router-dom";

export const SideNavigation = () => {
  return (
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item nav-profile"></li>
        <li class="nav-item">
          <Link to={"/dashboard"} class="nav-link">
            <span class="menu-title">Dashboard</span>
            <i class="mdi mdi-home menu-icon"></i>
          </Link>
        </li>
        <li class="nav-item">
          <Link to={"/users"} class="nav-link">
            <span class="menu-title">Users</span>
            <i class="mdi mdi-crosshairs-gps menu-icon"></i>
          </Link>
        </li>
        <li class="nav-item">
          <Link to={"/products"} class="nav-link">
            <span class="menu-title">Products</span>
            <i class="mdi mdi-contacts menu-icon"></i>
          </Link>
        </li>
        <li class="nav-item">
          <Link to={"/categories"} class="nav-link">
            <span class="menu-title">Categories</span>
            <i class="mdi mdi-format-list-bulleted menu-icon"></i>
          </Link>
        </li>

        <li class="nav-item">
          <Link to={"/orders"} class="nav-link">
            <span class="menu-title">Orders</span>
            <i class="mdi mdi-chart-bar menu-icon"></i>
          </Link>
        </li>
        <li class="nav-item">
          <Link to={"/cancel-orders"} class="nav-link">
            <span class="menu-title">Cancelled Orders</span>
            <i class="mdi mdi-chart-bar menu-icon"></i>
          </Link>
        </li>
        <li class="nav-item">
          <Link to={"/discount"} class="nav-link">
            <span class="menu-title">Discount</span>
            <i class="mdi mdi-format-list-bulleted menu-icon"></i>
          </Link>
        </li>
        <li class="nav-item">
          <Link to={"/ticket"} class="nav-link">
            <span class="menu-title">Tickets</span>
            <i class="mdi mdi-format-list-bulleted menu-icon"></i>
          </Link>
        </li>

        <hr />
        <li class="nav-item">
          <Link to={"/main-carasoul"} class="nav-link">
            <span class="menu-title">Carasoul</span>
            <i class="mdi mdi-format-list-bulleted menu-icon"></i>
          </Link>
        </li>
      </ul>
    </nav>
  );
};
