import { useEffect, useState } from "react";
import { apiConfig } from "../utils/apiConfig";
import { Footer } from "./Footer";
import { Link, useNavigate } from "react-router-dom";

export const DashboardView = () => {
  const [userCount, setUserCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [cancelOrderCount, setCancelOrderCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        // Fetch users count
        const userResponse = await apiConfig.getAllUsers();
        setUserCount(
          userResponse?.data?.data?.filter((e) => e.role !== "admin").length
        ); // Assuming the data is an array of users
        // Fetch products count
        const productResponse = await apiConfig.allProducts();
        setProductCount(productResponse?.data?.data?.length); // Assuming the data is an array of products
        // Fetch orders count
        const orderResponse = await apiConfig.getAllOrders();
        setOrderCount(orderResponse?.data?.data?.length); // Assuming the data is an array of orders
        const response = await apiConfig.getAllCancelOrder();
        setCancelOrderCount(response?.data?.data?.length);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          // If the token is expired or invalid
          localStorage.removeItem("token"); // Remove the expired token
          navigate("/"); // Redirect to login page
        }
      }
    };

    fetchCounts();
  }, []); // Run only once on component mount
  return (
    <>
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="page-header">
            <h3 class="page-title">
              <span class="page-title-icon bg-gradient-primary text-white me-2">
                <i class="mdi mdi-home"></i>
              </span>{" "}
              Dashboard
            </h3>
            <nav aria-label="breadcrumb">
              <ul class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                  <span></span>Overview{" "}
                  <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                </li>
              </ul>
            </nav>
          </div>
          <div class="row">
            <div class="col-md-4 stretch-card grid-margin">
              <div class="card bg-gradient-danger card-img-holder text-white">
                <Link
                  to={"/users"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div class="card-body">
                    <img
                      src="assets/images/dashboard/circle.svg"
                      class="card-img-absolute"
                      alt="circle-image"
                    />
                    <h4 class="font-weight-normal mb-3">
                      Total Number Of Users{" "}
                      <i class="mdi mdi-chart-line mdi-24px float-end"></i>
                    </h4>
                    <h2 class="mb-5">{userCount}</h2>
                    {/* <h6 class="card-text">Increased by 60%</h6> */}
                  </div>
                </Link>
              </div>
            </div>

            <div class="col-md-4 stretch-card grid-margin">
              <div class="card bg-gradient-info card-img-holder text-white">
                <Link
                  to={"/products"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div class="card-body">
                    <img
                      src="assets/images/dashboard/circle.svg"
                      class="card-img-absolute"
                      alt="circle-image"
                    />
                    <h4 class="font-weight-normal mb-3">
                      Total Number Of Products{" "}
                      <i class="mdi mdi-bookmark-outline mdi-24px float-end"></i>
                    </h4>
                    <h2 class="mb-5">{productCount}</h2>
                    {/* <h6 class="card-text">Decreased by 10%</h6> */}
                  </div>
                </Link>
              </div>
            </div>
            <div class="col-md-4 stretch-card grid-margin">
              <div class="card bg-gradient-success card-img-holder text-white">
                <Link
                  to={"/orders"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div class="card-body">
                    <img
                      src="assets/images/dashboard/circle.svg"
                      class="card-img-absolute"
                      alt="circle-image"
                    />
                    <h4 class="font-weight-normal mb-3">
                      Number of Orders{" "}
                      <i class="mdi mdi-diamond mdi-24px float-end"></i>
                    </h4>
                    <h2 class="mb-5">{orderCount}</h2>
                    {/* <h6 class="card-text">Increased by 5%</h6> */}
                  </div>
                </Link>
              </div>
            </div>
            <div class="col-md-4 stretch-card grid-margin">
              <div class="card bg-gradient-success card-img-holder text-white">
                <Link
                  to={"/cancel-orders"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div class="card-body">
                    <img
                      src="assets/images/dashboard/circle.svg"
                      class="card-img-absolute"
                      alt="circle-image"
                    />
                    <h4 class="font-weight-normal mb-3">
                      Number of Cancelled Orders{" "}
                      <i class="mdi mdi-diamond mdi-24px float-end"></i>
                    </h4>
                    <h2 class="mb-5">{orderCount}</h2>
                    {/* <h6 class="card-text">Increased by 5%</h6> */}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
