import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { apiConfig } from "../utils/apiConfig";

export const CategoryTable = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Filtered data for search
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiConfig.allCategories();
        setCategoryData(response.data.data);
        setFilteredData(response.data.data); // Initialize filtered data
      } catch (err) {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    // Apply search filtering
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = categoryData.filter((category) =>
      category.categoryName.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredData(filtered);
  }, [searchQuery, categoryData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEdit = (categoryId) => {
    console.log("Editing category with ID:", categoryId);
    navigate("/addCategorie", { state: { categoryId } });
  };

  const columns = [
    { id: "sNo", label: "S.No", minWidth: 50, align: "center" },
    { id: "categorie", label: "Categorie", minWidth: 150 },
    { id: "categorieImage", label: "Categorie Image", minWidth: 150 },
    { id: "description", label: "Description", minWidth: 200 },
    { id: "active", label: "Active", minWidth: 100, align: "center" },
    { id: "action", label: "Action", minWidth: 100, align: "center" },
  ];

  const dataMapping = {
    sNo: (row, index) => page * rowsPerPage + (index + 1),
    categorie: (row) => row.categoryName,
    categorieImage: (row) => (
      <img
        src={row.image}
        alt="Carousel"
        width="60"
        height="80"
        style={{ borderRadius: "5px" }}
      />
    ),
    description: (row) => row.description,
    active: (row) => (row.categoryActive ? "Yes" : "No"),
    action: (row) => (
      <span>
        <i
          style={{ cursor: "pointer" }}
          className="fa fa-pencil"
          onClick={() => handleEdit(row._id)}
        ></i>
      </span>
    ),
  };

  return (
    <>
      <div className="row">
        <div className="page-header">
          <h3 className="page-title"> Categories </h3>
          <nav>
            <Link
              to={"/addCategorie"}
              className="btn btn-gradient-primary btn-fw"
            >
              Add Categorie
            </Link>
          </nav>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body" style={{ padding: "25px" }}>
              {/* Search Input */}
              <TextField
                label="Search Categories"
                variant="outlined"
                size="small"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: "20px" }}
              />
              <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
                <TableContainer sx={{ Height: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row._id}
                            >
                              {columns.map((column) => (
                                <TableCell key={column.id} align={column.align}>
                                  {dataMapping[column.id](row, index)}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredData.length} // Use filtered data count
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
