import { CancelOrderTable } from "../subcomponents/CancelOrderTable";
import { Footer } from "./Footer";

export const CancelOrderView = () => {
  return (
    <>
      <div class="main-panel">
        <div class="content-wrapper">
          <CancelOrderTable />
        </div>

        <Footer />
      </div>
    </>
  );
};
