import { useLocation } from "react-router-dom";
import { CategoryTable } from "../subcomponents/CategoryTable"
import { Footer } from "./Footer"
import { AddCategorie } from "../subcomponents/Add/AddCategorie";

export const CategoryView = () => {

    const location = useLocation();
    return (
        <>
            <div class="main-panel">
               <div class="content-wrapper">
                   
                   {location.pathname === '/categories' && <CategoryTable/>}
                    {location.pathname === '/addCategorie' && <AddCategorie/>}
               </div>

               
           
           
               <Footer/>
           
               </div>
        </>
    )
} 