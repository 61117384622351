import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { apiConfig } from "../utils/apiConfig";

export const OrderTable = () => {
  const [orderData, setOrderData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // To store filtered data
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // Search input state
  const navigate = useNavigate();

  const orderStatusOptions = ["pending", "shipped", "delivered", "cancelled"];

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await apiConfig.getAllOrders();
        setOrderData(response.data.data); // Load all orders
        setFilteredData(response.data.data); // Initialize filtered data
      } catch (err) {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Update filtered data whenever the search query changes
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = orderData.filter((order) =>
      order._id.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredData(filtered);
  }, [searchQuery, orderData]);

  const handleOrderStatusChange = async (orderId, newStatus) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `Do you want to change the status to ${
        newStatus.charAt(0).toUpperCase() + newStatus.slice(1)
      }?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel",
    });

    if (result.isConfirmed) {
      try {
        await apiConfig.updateOrderById(orderId, { orderStatus: newStatus });
        setOrderData((prevData) =>
          prevData.map((order) =>
            order._id === orderId ? { ...order, orderStatus: newStatus } : order
          )
        );
        Swal.fire("Success!", "Order status updated successfully.", "success");
      } catch (error) {
        console.error("Error updating order status:", error);
        Swal.fire(
          "Error",
          "There was an error updating the order status.",
          "error"
        );
      }
    }
  };

  const columns = [
    { id: "sNo", label: "S.No", minWidth: 50, align: "center" },
    { id: "orderId", label: "Order Id", minWidth: 50, align: "center" },
    { id: "user", label: "User", minWidth: 150, align: "center" },
    { id: "address", label: "Address", minWidth: 200, align: "center" },
    {
      id: "phoneNumber",
      label: "Phone Number",
      minWidth: 150,
      align: "center",
    },
    { id: "products", label: "Products", minWidth: 250 },
    { id: "productImage", label: "Product Image", minWidth: 150 },
    {
      id: "totalAmount",
      label: "Total Amount",
      minWidth: 120,
      align: "center",
    },
    {
      id: "discountCode",
      label: "Discount Code",
      minWidth: 150,
      align: "center",
    },
    {
      id: "discountedAmount",
      label: "Discounted Amount",
      minWidth: 150,
      align: "center",
    },
    {
      id: "transaction",
      label: "Transaction Id",
      minWidth: 150,
      align: "center",
    },
    { id: "payment", label: "Payment Status", minWidth: 150, align: "center" },
    {
      id: "orderStatus",
      label: "Order Status",
      minWidth: 150,
      align: "center",
    },
    { id: "createdAt", label: "Created At", minWidth: 180, align: "center" },
    { id: "updatedAt", label: "Updated At", minWidth: 180, align: "center" },
    {
      id: "changeStatus",
      label: "Change Order Status",
      minWidth: 200,
      align: "center",
    },
  ];

  const orderDataMapping = {
    sNo: (row, index) => page * rowsPerPage + (index + 1),
    orderId: (row) => (
      <Tooltip title={row?._id?.toUpperCase()} arrow>
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
            maxWidth: "100px",
          }}
        >
          {row?._id?.toUpperCase()}...
        </span>
      </Tooltip>
    ),
    user: (row) =>
      `${row?.user.firstName} ${row?.user.lastName}` || row?.user._id,
    address: (row) => (
      <Tooltip title={row?.address.street || "N/A"} arrow>
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
            maxWidth: "100px",
          }}
        >
          {row?.address.street || "N/A"}...
        </span>
      </Tooltip>
    ),
    phoneNumber: (row) => row?.address?.phoneNumberId?.number,
    products: (row) =>
      row?.products
        ?.map((item) => `${item?.product?.productName} (x${item.quantity})`)
        .join(", "),
    productImage: (row) =>
      row?.products?.map((item) => (
        <img
          src={item?.product?.primaryImageUrl}
          alt="Carousel"
          width="70"
          height="80"
          style={{ borderRadius: "5px" }}
        />
      )),
    totalAmount: (row) => `₹ ${row.totalAmount.toFixed(2)}`,
    discountCode: (row) => (row.discountCode ? row.discountCode : "N/A"),
    discountedAmount: (row) =>
      `₹ ${
        row.discountedAmount?.toFixed(2)
          ? row.discountedAmount?.toFixed(2)
          : "N/A"
      }`,
    transaction: (row) => (row.transactionId ? row.transactionId : ""),
    payment: (row) => (
      <Alert
        severity={row?.paymentStatus !== "completed" ? "warning" : "success"}
      >
        {row?.paymentStatus.charAt(0).toUpperCase() +
          row.paymentStatus.slice(1) || row.payment._id}
      </Alert>
    ),
    orderStatus: (row) => {
      let severity = "success";
      if (row?.orderStatus === "pending") {
        severity = "warning";
      } else if (row?.orderStatus === "cancelled") {
        severity = "error";
      }

      return (
        <Alert severity={severity}>
          {row.orderStatus.charAt(0).toUpperCase() + row.orderStatus.slice(1)}
        </Alert>
      );
    },
    createdAt: (row) => new Date(row.createdAt).toLocaleDateString(),
    updatedAt: (row) => new Date(row.updatedAt).toLocaleDateString(),
    changeStatus: (row) => (
      <Select
        disabled={
          row.paymentStatus === "failed" || row.orderStatus === "cancelled"
            ? true
            : false
        }
        value={row.orderStatus}
        onChange={(e) => handleOrderStatusChange(row._id, e.target.value)}
        fullWidth
        sx={{
          width: 120,
          height: 30,
          fontSize: "0.875rem",
          padding: "4px",
        }}
      >
        {orderStatusOptions.map((status) => (
          <MenuItem key={status} value={status}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </MenuItem>
        ))}
      </Select>
    ),
  };

  return (
    <>
      <div className="row">
        <div className="page-header">
          <h3 className="page-title"> Order Tables </h3>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body" style={{ padding: "25px" }}>
              {/* Search Bar */}
              <TextField
                label="Search Order by ID"
                variant="outlined"
                size="small"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginBottom: "20px" }}
              />
              <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
                <TableContainer sx={{ Height: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row._id}
                            >
                              {columns.map((column) => {
                                const cellContent = orderDataMapping[column.id](
                                  row,
                                  index
                                );
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {cellContent}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(_, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(e) =>
                    setRowsPerPage(parseInt(e.target.value, 10))
                  }
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
