import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token'); // Replace 'token' with your actual token key
  const role = localStorage.getItem('role');

  // If token is not found, redirect to sign-in page
  if (!token || !(role === "admin")) {
    return <Navigate to="/" replace />;
  }else{
    <Navigate to={"/dashboard"}/>
  }

  // If token exists, render the children (protected component)
  return children;
};

export default ProtectedRoute;