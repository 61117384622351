import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiConfig } from "../../utils/apiConfig";
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


export const CarasoulTable = () => {

  const [carasoulData, setCarasoulData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();


  useEffect(()=>{
    const fetchUserData = async () => {
      try {
        const response = await apiConfig.getAllCarousels();
        console.log(response.data, "Checkin")
        setCarasoulData(response.data); // Assuming the API returns user data in response.data
      }catch (err) {
        if (err.response && err.response.status === 401) {
          // If the token is expired or invalid
          localStorage.removeItem('token'); // Remove the expired token
          navigate('/'); // Redirect to login page
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  },[])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = async (carasoulId) => {

      try {
        const response = await apiConfig.deleteCarousel(carasoulId)
        if(response.status === 204){
          window.location.reload();
        }
      }catch (err) {
        if (err.response && err.response.status === 401) {
          // If the token is expired or invalid
          localStorage.removeItem('token'); // Remove the expired token
          navigate('/'); // Redirect to login page
        }
      } finally {
        setLoading(false);
      }
  }

  const columns = [
    { id: 'sNo', label: 'S.No', minWidth: 50, align: 'center' },
    { id: 'title', label: 'Title', minWidth: 150 },
    { id: 'description', label: 'Description', minWidth: 250 },
    { id: 'imageUrl', label: 'Image URL', minWidth: 200, align: 'center' },
    { id: 'createdAt', label: 'Created At', minWidth: 150, align: 'center' },
    { id: 'updatedAt', label: 'Updated At', minWidth: 150, align: 'center' },
    { id: 'action', label: 'Action', minWidth: 100, align: 'center' },
  ];
  
  
  const dataMapping = {
    sNo: (row, index) => page * rowsPerPage + (index + 1),
    title: (row) => row.title,
    description: (row) => row.description,
    imageUrl: (row) => (
      <img src={row.imageUrl} alt="Carousel" width="100" height="60" style={{ borderRadius: '5px' }} />
    ),
    createdAt: (row) => new Date(row.createdAt).toLocaleDateString(),
    updatedAt: (row) => new Date(row.updatedAt).toLocaleDateString(),
    action: (row) => (
      <span>
        <i
          style={{ cursor: "pointer" }}
          className="fa fa-trash-o"
          onClick={() => handleDelete(row._id)}
        ></i>
      </span>
    ),
  };
  

  if (loading) {
    return <p>Loading...</p>;
  }

    return (
        <>
        <div class="row">
            <div class="page-header">
              <h3 class="page-title"> Carasoul Tables </h3>
              <nav>
                <Link to={"/addCarasoul"} class="btn btn-gradient-primary btn-fw">Add Carasoul</Link>
              </nav>
            </div>
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body" style={{padding:"25px"}}>
                    <Paper sx={{ width: '100%', overflow: 'hidden', padding: 1 }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    >
                                    {column.label}
                                    </TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {carasoulData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {dataMapping[column.id](row, index)}
                                            </TableCell>
                                        );
                                        })}
                                    </TableRow>
                                    );
                                })}
                            </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={carasoulData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                  </div>
                </div>
              </div>
              
              </div>
        </>
    )
}