import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { apiConfig } from "../utils/apiConfig";

export const CancelOrderTable = () => {
  const [orderData, setOrderData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Filtered data for search
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiConfig.getAllCancelOrder();
        setOrderData(response.data.data);
        setFilteredData(response.data.data); // Initialize filtered data
      } catch (err) {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Update filtered data whenever the search query changes
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = orderData.filter((order) =>
      order._id.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredData(filtered);
  }, [searchQuery, orderData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "sNo", label: "S.No", minWidth: 50, align: "center" },
    { id: "orderId", label: "Order Id", minWidth: 50, align: "center" },
    { id: "user", label: "User", minWidth: 150, align: "center" },
    {
      id: "totalAmount",
      label: "Total Amount",
      minWidth: 120,
      align: "center",
    },
    {
      id: "discountCode",
      label: "Discount Code",
      minWidth: 150,
      align: "center",
    },
    {
      id: "discountedAmount",
      label: "Discounted Amount",
      minWidth: 150,
      align: "center",
    },
    {
      id: "transaction",
      label: "Transaction Id",
      minWidth: 150,
      align: "center",
    },
    {
      id: "refundStatus",
      label: "Refund Status",
      minWidth: 150,
      align: "center",
    },
    {
      id: "cancellationDate",
      label: "Cancellation Date",
      minWidth: 180,
      align: "center",
    },
  ];

  const orderDataMapping = {
    sNo: (row, index) => page * rowsPerPage + (index + 1),
    orderId: (row) => (
      <Tooltip title={row?._id?.toUpperCase()} arrow>
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
            maxWidth: "100px",
          }}
        >
          {row?._id?.toUpperCase()}...
        </span>
      </Tooltip>
    ),
    user: (row) =>
      `${row?.customerId?.firstName} ${row?.customerId?.lastName}` ||
      row?.customerId?._id,
    totalAmount: (row) => `₹ ${row?.orderId?.totalAmount.toFixed(2)}`,
    discountCode: (row) =>
      row?.orderId?.discountCode ? row?.orderId?.discountCode : "N/A",
    discountedAmount: (row) =>
      `₹ ${
        row?.orderId?.discountedAmount?.toFixed(2)
          ? row?.orderId?.discountedAmount?.toFixed(2)
          : "N/A"
      }`,
    transaction: (row) =>
      row?.orderId?.transactionId ? row?.orderId?.transactionId : "N/A",
    refundStatus: (row) => (
      <Alert
        severity={row?.refundStatus === "processed" ? "success" : "warning"}
      >
        {row?.refundStatus}
      </Alert>
    ),
    cancellationDate: (row) =>
      new Date(row.cancellationDate).toLocaleDateString(),
  };

  return (
    <>
      <div className="row">
        <div className="page-header">
          <h3 className="page-title"> Cancelled Order Tables </h3>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body" style={{ padding: "25px" }}>
              {/* Search Bar */}
              <TextField
                label="Search by Order Id"
                variant="outlined"
                size="small"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginBottom: "20px" }}
              />
              <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
                <TableContainer sx={{ Height: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row._id}
                            >
                              {columns.map((column) => {
                                const cellContent = orderDataMapping[column.id](
                                  row,
                                  index
                                );
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {cellContent}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(_, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(e) =>
                    setRowsPerPage(parseInt(e.target.value, 10))
                  }
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
