import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { apiConfig } from "../utils/apiConfig";

export const ProductTable = () => {
  const [productData, setProductData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // To store filtered data
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // Filter input
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiConfig.allProducts();
        setProductData(response.data.data);
        setFilteredData(response.data.data); // Initialize filtered data
      } catch (err) {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    // Filter the product data based on the search query
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = productData.filter((product) =>
      product.productName.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredData(filtered);
  }, [searchQuery, productData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const columns = [
    { id: "sNo", label: "S.No", minWidth: 50, align: "center" },
    { id: "product", label: "Product", minWidth: 150 },
    { id: "productImage", label: "Product Image", minWidth: 150 },
    { id: "categories", label: "Categories", minWidth: 150 },
    { id: "price", label: "Price", minWidth: 100, align: "center" },
    {
      id: "sellingPrice",
      label: "Selling Price",
      minWidth: 120,
      align: "center",
    },
    {
      id: "stockQuantity",
      label: "Stock Quantity",
      minWidth: 150,
      align: "center",
    },
    { id: "action", label: "Action", minWidth: 100, align: "center" },
  ];

  const dataMapping = {
    sNo: (row, index) => page * rowsPerPage + (index + 1),
    product: (row) => row?.productName,
    productImage: (row) => (
      <img
        src={row.primaryImageUrl}
        alt="Carousel"
        width="80"
        height="80"
        style={{ borderRadius: "5px" }}
      />
    ),
    categories: (row) => row?.categoryId?.categoryName,
    price: (row) => `₹ ${row?.price}`,
    sellingPrice: (row) => `₹ ${row?.sellingPrice}`,
    stockQuantity: (row) => row.stockQuantity,
    action: (row) => (
      <span>
        <i
          style={{ cursor: "pointer" }}
          className="fa fa-pencil"
          onClick={() =>
            navigate("/addProduct", { state: { productId: row._id } })
          }
        ></i>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <i
          style={{ cursor: "pointer" }}
          className="fa fa-trash-o"
          onClick={() => console.log("Delete", row._id)}
        ></i>
      </span>
    ),
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="row">
        <div className="page-header">
          <h3 className="page-title"> Product Tables </h3>
          <nav>
            <Link
              to={"/addProduct"}
              className="btn btn-gradient-primary btn-fw"
            >
              Add Product
            </Link>
          </nav>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body" style={{ padding: "25px" }}>
              {/* Search Bar */}
              <TextField
                label="Search Product"
                variant="outlined"
                size="small"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: "20px" }}
              />
              <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
                <TableContainer sx={{ Height: "80vh" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row._id}
                            >
                              {columns.map((column) => (
                                <TableCell key={column.id} align={column.align}>
                                  {dataMapping[column.id](row, index)}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredData.length} // Use filtered data count
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
