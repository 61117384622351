import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { apiConfig } from "../utils/apiConfig";
import Swal from "sweetalert2";

export const TicketTable = () => {
  const [ticketData, setTicketData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Filtered data for search
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // Search query state

  const ticketStatusOptions = [
    "new",
    "in progress",
    "on hold",
    "resolved",
    "closed",
  ];

  useEffect(() => {
    const fetchTicketData = async () => {
      try {
        const response = await apiConfig.getAllQuery(); // Replace with your actual API call
        console.log(response, "Response");
        setTicketData(response.data?.data);
        setFilteredData(response.data?.data); // Initialize filtered data
      } catch (err) {
        console.error("Error fetching ticket data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchTicketData();
  }, []);

  useEffect(() => {
    // Apply search filtering
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = ticketData.filter(
      (ticket) =>
        ticket.firstName.toLowerCase().includes(lowerCaseQuery) ||
        ticket.lastName.toLowerCase().includes(lowerCaseQuery) ||
        ticket.email.toLowerCase().includes(lowerCaseQuery) ||
        ticket.ticketId.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredData(filtered);
  }, [searchQuery, ticketData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleTicketStatusChange = async (orderId, newStatus) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `Do you want to change the status to ${
        newStatus.charAt(0).toUpperCase() + newStatus.slice(1)
      }?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel",
    });

    if (result.isConfirmed) {
      try {
        await apiConfig.updateTicketById(orderId, { ticketStatus: newStatus });
        setTicketData((prevData) =>
          prevData.map((order) =>
            order._id === orderId
              ? { ...order, ticketStatus: newStatus }
              : order
          )
        );
        Swal.fire("Success!", "Ticket status updated successfully.", "success");
      } catch (error) {
        console.error("Error updating order status:", error);
        Swal.fire(
          "Error",
          "There was an error updating the order status.",
          "error"
        );
      }
    }
  };

  const columns = [
    { id: "sNo", label: "S.No", minWidth: 50, align: "center" },
    { id: "firstName", label: "First Name", minWidth: 150 },
    { id: "lastName", label: "Last Name", minWidth: 150 },
    { id: "phone", label: "Phone", minWidth: 150 },
    { id: "email", label: "Email", minWidth: 200 },
    { id: "ticketId", label: "Ticket ID", minWidth: 200 },
    { id: "createdAt", label: "Created At", minWidth: 150 },
    { id: "comment", label: "Comment", minWidth: 200 },
    {
      id: "changeStatus",
      label: "Change Ticket Status",
      minWidth: 200,
      align: "center",
    },
  ];

  const dataMapping = {
    sNo: (row, index) => page * rowsPerPage + (index + 1),
    firstName: (row) => row.firstName,
    lastName: (row) => row.lastName,
    phone: (row) => row.phone,
    email: (row) => row.email,
    ticketId: (row) => row.ticketId,
    createdAt: (row) => new Date(row.createdAt).toLocaleString(),
    comment: (row) => row.comment,
    changeStatus: (row) => (
      <Select
        // disabled={
        //   row.paymentStatus === "failed" || row.orderStatus === "cancelled"
        //     ? true
        //     : false
        // }
        value={row.ticketStatus}
        onChange={(e) => handleTicketStatusChange(row._id, e.target.value)}
        fullWidth
        sx={{
          width: 120,
          height: 30,
          fontSize: "0.875rem",
          padding: "4px",
        }}
      >
        {ticketStatusOptions.map((status) => (
          <MenuItem key={status} value={status}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </MenuItem>
        ))}
      </Select>
    ),
  };

  return (
    <div className="row">
      <div className="page-header">
        <h3 className="page-title"> Tickets </h3>
      </div>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body" style={{ padding: "25px" }}>
            {/* Search Input */}
            <TextField
              label="Search Tickets"
              variant="outlined"
              size="small"
              fullWidth
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ marginBottom: "20px" }}
            />
            <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
              <TableContainer sx={{ maxHeight: "70vh" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                          >
                            {columns.map((column) => (
                              <TableCell key={column.id} align={column.align}>
                                {dataMapping[column.id](row, index)}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredData.length} // Use filtered data count
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};
