import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token"); // Replace 'token' with your actual token key
    const role = localStorage.getItem("role");

    // If token is not found, redirect to sign-in page
    if (token && role === "admin") {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const apiUrl = `${process.env.REACT_APP_API_URL}/signin`; // Adjust the endpoint as needed

    try {
      const response = await axios.post(apiUrl, {
        email,
        password,
      });

      console.log(response, "Respose");

      const { token, user } = response.data; // Assuming the API returns a token

      // Save the token to localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("role", user.role);
      localStorage.setItem("name", user.name);
      // Redirect to the dashboard or another page
      window.location.href = "/dashboard"; // Replace with useNavigate if using react-router-dom
    } catch (err) {
      // Check if the error response exists and set the error message
      if (err.response && err.response.data) {
        setError(err.response.data.message || "Sign-in failed");
      } else {
        setError("Sign-in failed");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div
                  className="auth-form-light text-left p-5"
                  style={{ textAlign: "center" }}
                >
                  <div className="brand-logo">
                    {/* <h3>TheMonkeySoul</h3> */}
                    <img
                      src="https://res.cloudinary.com/dbf9ry82l/image/upload/v1732211254/5_hhj9f6.png"
                      alt="Logo"
                    />
                  </div>
                  <h4>Backend Dashboard</h4>
                  <h6 className="font-weight-light">Sign in to continue.</h6>
                  <form className="pt-3" onSubmit={handleSignIn}>
                    <div className="form-group">
                      <input
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="email"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <div className="mt-3 d-grid gap-2">
                      <button
                        className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Signing In..." : "Sign In"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script src="../../assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="../../assets/js/off-canvas.js"></script>
      <script src="../../assets/js/misc.js"></script>
      <script src="../../assets/js/settings.js"></script>
      <script src="../../assets/js/todolist.js"></script>
      <script src="../../assets/js/jquery.cookie.js"></script>
    </>
  );
};
