import { Footer } from "./Footer";
import { TicketTable } from "../subcomponents/TicketTable";

export const TicketView = () => {
  return (
    <>
      <div class="main-panel">
        <div class="content-wrapper">
          <TicketTable />
        </div>

        <Footer />
      </div>
    </>
  );
};
