export const urls = {
  // All Users
  allUsers: `${process.env.REACT_APP_USER_API_URL}/allUsers`,
  getUserById: `${process.env.REACT_APP_USER_API_URL}/getUser`,
  addUser: `${process.env.REACT_APP_USER_API_URL}/addUser`,
  updateUser: `${process.env.REACT_APP_USER_API_URL}/updateUser`,
  deactivateUser: `${process.env.REACT_APP_USER_API_URL}/deactivateUser/:id`,
  activateUser: `${process.env.REACT_APP_USER_API_URL}/activateUser/:id`,
  deleteUser: `${process.env.REACT_APP_USER_API_URL}/deleteUser/:id`,
  searchUsers: `${process.env.REACT_APP_USER_API_URL}/search`,

  // All Products
  allProducts: `${process.env.REACT_APP_PRODUCT_API_URL}/allProducts`,
  deleteProduct: `${process.env.REACT_APP_PRODUCT_API_URL}/deleteProduct`,
  getProductbyId: `${process.env.REACT_APP_PRODUCT_API_URL}/getProductById`,
  addProduct: `${process.env.REACT_APP_PRODUCT_API_URL}/addProduct`,
  updateProduct: `${process.env.REACT_APP_PRODUCT_API_URL}/updateProduct`,

  // All Categories
  allCategories: `${process.env.REACT_APP_CATEGORIE_API_URL}/allCategories`,
  getCategoryById: `${process.env.REACT_APP_CATEGORIE_API_URL}/categoryById`,
  addCategorie: `${process.env.REACT_APP_CATEGORIE_API_URL}/addCategorie`,
  updateCategorie: `${process.env.REACT_APP_CATEGORIE_API_URL}/updateCategorie`,

  // All Discounts
  createDiscount: `${process.env.REACT_APP_DISCOUNT_API_URL}/createDiscount`,
  getDiscountById: `${process.env.REACT_APP_DISCOUNT_API_URL}/getDiscountById/:id`,
  getAllDiscounts: `${process.env.REACT_APP_DISCOUNT_API_URL}/getAllDiscount`,
  updateDiscount: `${process.env.REACT_APP_DISCOUNT_API_URL}/updateDiscount/:id`,
  deleteDiscount: `${process.env.REACT_APP_DISCOUNT_API_URL}/deleteDiscount/:id`,
  applyDiscount: `${process.env.REACT_APP_DISCOUNT_API_URL}/applyDiscount`,
  validateDiscount: `${process.env.REACT_APP_DISCOUNT_API_URL}/validateDiscount`,

  // All Orders
  createOrder: `${process.env.REACT_APP_ORDER_API_URL}/createOrder`,
  getAllOrders: `${process.env.REACT_APP_ORDER_API_URL}/getAllOrder`,
  getAllCancelOrder: `${process.env.REACT_APP_ORDER_API_URL}/getAllCancelOrder`,
  getOrderById: `${process.env.REACT_APP_ORDER_API_URL}/getOrderByID/:id`,
  updateOrderById: `${process.env.REACT_APP_ORDER_API_URL}/updateOrderById/:id`,
  deleteOrder: `${process.env.REACT_APP_ORDER_API_URL}/deleteOrder/:id`,

  // All Drive Upload
  uploadImageUrl: `${process.env.REACT_APP_BACK_END_URL}/drive/upload`,

  createCarousel: `${process.env.REACT_APP_CAROUSEL_API_URL}/uploadCarasoul`,
  getAllCarousels: `${process.env.REACT_APP_CAROUSEL_API_URL}/allCarasoul`,
  getCarouselById: `${process.env.REACT_APP_CAROUSEL_API_URL}/getCarasoulById/:id`,
  deleteCarousel: `${process.env.REACT_APP_CAROUSEL_API_URL}/delete/:id`,

  allQuery: `${process.env.REACT_APP_QUERY_API_URL}/getAllQuery`,
  updateTicketById: `${process.env.REACT_APP_QUERY_API_URL}/updateTicketById/:id`,
};
