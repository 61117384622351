import { useLocation } from "react-router-dom"
import { Footer } from "./Footer"
import { DiscountTable } from "../subcomponents/DiscountTable";
import { AddDiscount } from "../subcomponents/Add/AddDiscount";

export const DiscountView = () => {
    const location = useLocation();
    return (
        <>
            <div class="main-panel">
               <div class="content-wrapper">
                {location.pathname === '/discount' && <DiscountTable/>}
                {location.pathname === '/addDiscountCoupen' && <AddDiscount/>}
                {/* <AddProduct/> */}
               </div>
               <Footer/>
           
               </div>
        </>
    )
}