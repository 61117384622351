import { UserTable } from "../subcomponents/UserTable"
import { Footer } from "./Footer"

export const UserView = () => {
    return (
        <>
        <div class="main-panel">
               <div class="content-wrapper">
                   <UserTable/>
               </div>

               
           
           
               <Footer/>
           
               </div>
   </>
    )
}