import React from 'react';
import { OrderView } from '../components/OrderView';
import { TopNavigation } from '../components/TopNavigation';
import { SideNavigation } from '../components/SideNavigation';
import { CategoryView } from '../components/CategoryView';

const Category = () => {
    return (
        <>
        <div class="container-scroller">

            <TopNavigation/>

            <div class="container-fluid page-body-wrapper">

                <SideNavigation/>
                
    <CategoryView/>

</div>

</div>
<script src="assets/vendors/js/vendor.bundle.base.js"></script>
<script src="assets/vendors/chart.js/chart.umd.js"></script>
<script src="assets/vendors/bootstrap-datepicker/bootstrap-datepicker.min.js"></script>
<script src="assets/js/off-canvas.js"></script>
<script src="assets/js/misc.js"></script>
<script src="assets/js/settings.js"></script>
<script src="assets/js/todolist.js"></script>
<script src="assets/js/jquery.cookie.js"></script>
<script src="assets/js/dashboard.js"></script>
        </>
    )
};

export default Category;