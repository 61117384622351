import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiConfig } from "../utils/apiConfig";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

export const DiscountTable = () => {
  const [discountData, setDiscountData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiConfig.getAllDiscounts();
        setDiscountData(response.data.data); // Assuming the API returns user data in response.data
      } catch (err) {
        if (err.response && err.response.status === 401) {
          // If the token is expired or invalid
          localStorage.removeItem("token"); // Remove the expired token
          navigate("/"); // Redirect to login page
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleEdit = (discountId) => {
    console.log("Editing discount with ID:", discountId);
    navigate("/addDiscountCoupen", { state: { discountId } });
  };

  const handleDelete = async (discountId) => {
    try {
      const response = await apiConfig.deleteDiscount(discountId);
      if (response.status === 204) {
        window.location.reload();
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        // If the token is expired or invalid
        localStorage.removeItem("token"); // Remove the expired token
        navigate("/"); // Redirect to login page
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "sNo", label: "S.No", minWidth: 50, align: "center" },
    { id: "code", label: "Code", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 200 },
    { id: "discountType", label: "Discount Type", minWidth: 150 },
    { id: "amount", label: "Amount", minWidth: 150 },
    {
      id: "minPurchase",
      label: "Minimum Purchase",
      minWidth: 150,
      align: "center",
    },
    {
      id: "maxDiscountAmount",
      label: "Maximum Discount Amount",
      minWidth: 180,
      align: "center",
    },
    { id: "startDate", label: "Start Date", minWidth: 150, align: "center" },
    { id: "expiryDate", label: "Expiry Date", minWidth: 150, align: "center" },
    { id: "usageLimit", label: "Usage Limit", minWidth: 150, align: "center" },
    { id: "usageCount", label: "Usage Count", minWidth: 150, align: "center" },
    { id: "active", label: "Active", minWidth: 80, align: "center" },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  const dataMapping = {
    sNo: (row, index) => page * rowsPerPage + (index + 1),
    code: (row) => row.code,
    description: (row) => row.description,
    discountType: (row) => row.discountType,
    amount: (row) => `₹ ${row.amount}`,
    minPurchase: (row) => `₹ ${row.minPurchaseAmount}`,
    maxDiscountAmount: (row) => `₹ ${row.maxDiscountAmount}`,
    startDate: (row) => new Date(row.startDate).toLocaleDateString(),
    expiryDate: (row) => new Date(row.expiryDate).toLocaleDateString(),
    usageLimit: (row) => row.usageLimit,
    usageCount: (row) => row.usedCount,
    active: (row) => (row.active ? "Yes" : "No"),
    actions: (row) => (
      <span>
        <i
          style={{ cursor: "pointer" }}
          class="fa fa-pencil"
          onClick={() => handleEdit(row._id)}
        ></i>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <i
          style={{ cursor: "pointer" }}
          class="fa fa-trash-o"
          onClick={() => handleDelete(row._id)}
        ></i>
      </span>
    ),
  };

  if (loading) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <div class="row">
        <div class="page-header">
          <h3 class="page-title"> Discount Tables </h3>
          <nav>
            <Link
              to={"/addDiscountCoupen"}
              class="btn btn-gradient-primary btn-fw"
            >
              Add Discount
            </Link>
          </nav>
        </div>
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body" style={{ padding: "25px" }}>
              <Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
                <TableContainer sx={{ Height: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {discountData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {dataMapping[column.id](row, index)}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={discountData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
