import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { apiConfig } from "../../../utils/apiConfig";
import {
  TextField,
  Button,
  Box,
} from "@mui/material";

export const AddCarasoul = () => {

    const location = useLocation();
    const { carasoulId } = location.state || {};
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [edit, setEdit] = useState(false);
    const [showImage, setShowImage] = useState([])
    const [image, setImage] = useState();
    const navigate = useNavigate();
    
    useEffect(()=>{
        const fetchProductData = async () => {
          if (carasoulId) {
            try {
              const response = await apiConfig.getCarouselById(carasoulId)
    
              const data = response.data
              setTitle(data.title)
              setDescription(data.description)
              setShowImage((prevData) => [
                data.imageUrl
            ]);
              setEdit(true)
            } finally {
              setLoading(false); // Set loading to false after fetching
            }
          } else {
            setLoading(false); // If no carasoulId, just set loading to false
          }
        }
        fetchProductData();
      },[carasoulId])
    
      const handleImage = (event) => {
        console.log(event, "Event Check")
        setImage(event.target.files[0])
      }
    
      const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission
    
        const formData = new FormData(); // Create a new FormData object
    
        formData.append('title', title);
        formData.append('description', description);
    
        if(!edit){
          try {
            console.log(image, "image")
            formData.append('image', image);
            const response = await apiConfig.createCarousel(formData);
            // Handle success (e.g., show a success message or redirect)
            console.log('Carasoul added successfully:', response.data);
            navigate('/main-carasoul'); // Redirect to the main-carasoul page
          } catch (err) {
            console.error('Error adding product:', err);
            // Handle error (e.g., show an error message)
          }
        }
    };
    
    
      if (loading) {
        return <p>Loading...</p>;
      }
    
      return (
        <>
        <div class="row">
            <div class="page-header">
              <h3 class="page-title"> Carasoul Tables </h3>
              <nav>
                <Link to={"/main-carasoul"} class="btn btn-gradient-dark btn-fw">Back</Link>
              </nav>
            </div>
            <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">{!edit ? "Add" : "Edit"} Product</h4>
                      <p class="card-description"> Basic form elements </p>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <form onSubmit={handleSubmit}>
                          <Box sx={{ mb: 2 }}>
                            <TextField
                              fullWidth
                              label="Title"
                              variant="outlined"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              required
                            />
                          </Box>
                          <Box sx={{ mb: 2 }} display="flex">
                            <input
                              type="file"
                              onChange={handleImage}
                            />

                            {
                              showImage?.map((item)=>(
                                <div style={{width: "25%", height: "25%"}}>
                                  <img src={item} alt="Google Drive" width={100} height={100}></img>
                                </div>
                              ))
                            }
                          </Box>
                          <Box sx={{ mb: 2 }}>
                            <TextField
                              label="Description"
                              variant="outlined"
                              multiline
                              rows={4}
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              required
                              fullWidth
                            />
                          </Box>
                          <Box>
                            <Button type="submit" variant="contained" color="primary">
                              {edit ? "Update" : "Submit"}
                            </Button>
                            <Button variant="outlined" color="secondary" sx={{ ml: 2 }}>Cancel</Button>
                          </Box>
                        </form>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
        </>
    )
}