import { useLocation } from "react-router-dom"
import { Footer } from "../Footer"
import { CarasoulTable } from "../../subcomponents/MainFrontEnd/CarasoulTable";
import { AddCarasoul } from "../../subcomponents/MainFrontEnd/Add/AddCarasoul";

export const CarasoulView = () => {
    const location = useLocation();
    return (
        <>
             <div class="main-panel">
               <div class="content-wrapper">
                {location.pathname === '/main-carasoul' && <CarasoulTable/>}
                {location.pathname === '/addCarasoul' && <AddCarasoul/>}
                {/* <AddProduct/> */}
               </div>
               <Footer/>
           
               </div>
        </>
    )
}