import "./App.css";
import { Dashboard } from "./pages/Dashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Products from "./pages/Products";
import Users from "./pages/Users";
import Order from "./pages/Order";
import Cart from "./pages/Cart";
import ProtectedRoute from "./Routes/ProtectedRoute";
import { SignIn } from "./pages/SignIn";
import Category from "./pages/Category";
import Discount from "./pages/Disacount";
import { Error } from "./pages/error/Error";
import { Carasoul } from "./pages/MainFrontEnd/Carasoul";
import CancelOrder from "./pages/CancelOrder";
import Ticket from "./pages/Ticket";

function App() {
  return (
    <Router>
      <Routes>
        {/* Main Backend Routes */}
        <Route path="/" element={<SignIn />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/products"
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addProduct"
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute>
              <Order />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cancel-orders"
          element={
            <ProtectedRoute>
              <CancelOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <ProtectedRoute>
              <Cart />
            </ProtectedRoute>
          }
        />

        <Route
          path="/discount"
          element={
            <ProtectedRoute>
              <Discount />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addDiscountCoupen"
          element={
            <ProtectedRoute>
              <Discount />
            </ProtectedRoute>
          }
        />

        <Route
          path="/categories"
          element={
            <ProtectedRoute>
              <Category />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ticket"
          element={
            <ProtectedRoute>
              <Ticket />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addCategorie"
          element={
            <ProtectedRoute>
              <Category />
            </ProtectedRoute>
          }
        />

        {/* Changeable routes of the Main MonkeySoul Website */}
        <Route
          path="/main-carasoul"
          element={
            <ProtectedRoute>
              <Carasoul />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addCarasoul"
          element={
            <ProtectedRoute>
              <Carasoul />
            </ProtectedRoute>
          }
        />
        <Route path="/*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
