import { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  FormGroup,
  Typography,
  Box,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { apiConfig } from "../../utils/apiConfig";

export const AddCategorie = () => {
  const location = useLocation();
  const { categoryId } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  // const [sizes, setSizes] = useState([]); // State to hold selected sizes
  const [categoryActive, setCategoryActive] = useState("");
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState();
  const navigate = useNavigate();

  // const availableSizes = ["XS", "S", "M", "L", "XL", "XXL"];

  useEffect(() => {
    const fetchProductData = async () => {
      if (categoryId) {
        try {
          const response = await apiConfig.getCategoriebyId(categoryId);
          const data = response.data.data;
          setCategoryName(data.categoryName);
          setDescription(data.description);
          setCategoryActive(data.categoryActive);
          // setSizes(data.sizes || []); // Set existing sizes if editing
          setEdit(true);
        } finally {
          setLoading(false); // Set loading to false after fetching
        }
      } else {
        setLoading(false); // If no categoryId, just set loading to false
      }
    };
    fetchProductData();
  }, [categoryId]);

  // const handleSizeChange = (size) => {
  //   // Toggle size selection
  //   setSizes((prevSizes) => {
  //     if (prevSizes.includes(size)) {
  //       return prevSizes.filter((s) => s !== size); // Remove size if already selected
  //     } else {
  //       return [...prevSizes, size]; // Add size if not selected
  //     }
  //   });
  // };

  const handleImage = (event) => {
    console.log(event, "Event Check");
    setImage(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setLoading(true); // Set loading to true before submitting
    const categorie = {
      categoryName,
      description,
      categoryActive,
      // sizes, // Include selected sizes
    };
    const formData = new FormData();
    formData.append("categoryName", categoryName);
    formData.append("description", description);
    formData.append("categoryActive", categoryActive);
    // sizes.forEach((size) => formData.append("sizes[]", size));

    if (!edit) {
      if (image) {
        formData.append("image", image); // Append the image file
      }
      try {
        const response = await apiConfig.addCategorie(formData);
        // Handle success (e.g., show a success message or redirect)
        console.log("Categorie added successfully:", response.data);
        setLoading(false);
        navigate("/categories"); // Redirect to the products page
      } catch (err) {
        console.error("Error adding product:", err);
        setLoading(false);
        // Handle error (e.g., show an error message)
      }
    } else {
      // if (image) {
      //   formData.append('image', image); // Append the image file
      // }

      try {
        const response = await apiConfig.updateCategorie(categorie, categoryId);
        // Handle success (e.g., show a success message or redirect)
        console.log("Categorie added successfully:", response.data);
        setLoading(false);
        navigate("/categories"); // Redirect to the products page
      } catch (err) {
        console.error("Error adding product:", err);
        setLoading(false);
        // Handle error (e.g., show an error message)
      }
    }
  };

  return (
    <>
      <div class="row">
        <div class="page-header">
          <h3 class="page-title"> Categorie Form </h3>
          <nav>
            <Link to={"/categories"} class="btn btn-gradient-dark btn-fw">
              Back
            </Link>
          </nav>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{!edit ? "Add" : "Edit"} Categorie</h4>
              <p class="card-description"> Basic form elements </p>
              <form onSubmit={handleSubmit} style={{ marginTop: 20 }}>
                <FormControl fullWidth margin="normal" required>
                  <TextField
                    label="Category Name"
                    variant="outlined"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal" required>
                  <InputLabel>Action</InputLabel>
                  <Select
                    label="Action"
                    value={categoryActive}
                    onChange={(e) => setCategoryActive(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Select a Category</em>
                    </MenuItem>
                    <MenuItem value="true">Active</MenuItem>
                    <MenuItem value="false">Inactive</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormControl>
                {/* <Typography variant="h6" gutterBottom>
                  Sizes
                </Typography>
                <FormGroup row>
                  {availableSizes.map((size) => (
                    <FormControlLabel
                      key={size}
                      control={
                        <Checkbox
                          checked={sizes.includes(size)}
                          onChange={() => handleSizeChange(size)}
                          name={size}
                        />
                      }
                      label={size}
                    />
                  ))}
                </FormGroup> */}
                <Box sx={{ mb: 2 }} display="flex">
                  <input type="file" onChange={handleImage} />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  style={{ marginTop: 20 }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    startIcon={
                      loading && <CircularProgress size={20} color="inherit" />
                    }
                  >
                    {loading ? "Processing" : edit ? "Update" : "Submit"}
                  </Button>
                  {/* <Button type="submit" variant="contained" color="primary">
                    {edit ? "Update" : "Submit"}
                  </Button> */}
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ marginLeft: 10 }}
                    onClick={() => navigate("/categories")}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
